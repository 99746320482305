const { getAccoladesHeaderComponent } = require('../profile/react/accoladesHeader');

function hydrateAccoladesHeaderComponent() {
	const globalHeaderWrapper = document.querySelector('#globalHeader');

	if (globalHeaderWrapper) {
		const { meta, isUs } = window.forbes;

		const {
			globalHeaderData, isProd, name, isAccoladesHeader, isMobile, isFda, isNewsletter,
		} = window.forbes['simple-site'];

		if (isAccoladesHeader) {
			getAccoladesHeaderComponent({
				meta,
				isUs,
				globalHeaderData,
				isProd,
				isMobile,
				profileName: name,
				showGetInTouch: true,
				isFda,
				isNewsletter,
			}).hydrate(globalHeaderWrapper);
		}
	}
}

hydrateAccoladesHeaderComponent();
