const React = require('react');

const {
	useState,
	useEffect,
	useRef,
} = React;
const { PropTypes } = require('prop-types');

const { default: GlobalHeader } = require('@forbes/fbs-components/dist/cjs/components/GlobalHeader/GlobalHeader');
const { default: ButtonCTA } = require('@forbes/fbs-components/dist/cjs/components/Button/ButtonCTA');
const { forbesF } = require('@forbes/fbs-icons/dist/index');
const { useGlobalHeader } = require('../hooks/useGlobalHeader');
const { fireGAEvent } = require('../../shared/trackingUtils');

const AccoladesHeader = ({ isMobile, headerParams, profileName }) => {
	const [showAnimatedNav, setshowAnimatedNav] = useState(false);
	const [userScrolled, setUserScrolled] = useState(false);

	const leadSpaceBlockRef = useRef(null);

	const {
		meta,
		isUs,
		isProd,
		isFda,
		isNewsletter,
	} = headerParams;

	const {
		currentUrl,
		forceButtonDisplay,
		loginButtonTrackingProps,
		menuButtonTrackingProps,
		newsLetterSubscribeTrackingProps,
		searchClickHandler,
		subscribeBtnProps,
	} = useGlobalHeader({
		meta,
		isProd,
		isUs,
		isFda,
		isNewsletter,
	});

	const handleGetInTouchTracking = () => {
		fireGAEvent({
			eventAction: 'Click',
			eventLabel: 'Get-in-touch-top-nav-hyperlink',
		});
	};

	useEffect(() => {
		if (window) {
			leadSpaceBlockRef.current = document?.querySelector('.listuser-leadspace__info');
		}
	}, []);

	useEffect(() => {
		const handleUserScroll = () => {
			setUserScrolled(true);
		};

		const handleKeyDown = (event) => {
			if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
				handleUserScroll();
			}
		};

		const removeScrollEventListeners = () => {
			window.removeEventListener('wheel', handleUserScroll);
			window.removeEventListener('touchmove', handleUserScroll);
			window.removeEventListener('keydown', handleKeyDown);
		};

		if (!userScrolled) {
			window.addEventListener('wheel', handleUserScroll, { once: true });
			window.addEventListener('touchmove', handleUserScroll, { once: true });
			window.addEventListener('keydown', handleKeyDown);
		}

		return removeScrollEventListeners;
	}, [userScrolled]);

	useEffect(() => {
		let leadSpaceInView = true;

		const handleScroll = (scroll) => {
			scroll.forEach((scrolling) => {
				if (scrolling.target === leadSpaceBlockRef.current) {
					leadSpaceInView = scrolling.isIntersecting;
				}
			});
			setshowAnimatedNav(!leadSpaceInView);
		};

		if (leadSpaceBlockRef.current) {
			const observer = new IntersectionObserver(handleScroll, {
				threshold: isMobile ? 0.06 : 0.1,
			});

			const engagementByline = leadSpaceBlockRef.current;
			if (userScrolled) {
				if (engagementByline) {
					observer.observe(engagementByline);
				}
			}

			return () => {
				if (engagementByline) observer.unobserve(engagementByline);
			};
		}

		return undefined;
	}, [leadSpaceBlockRef, isMobile, userScrolled]);

	const headerStyles = showAnimatedNav ? 'headerHidden' : 'headerVisible';
	const engagementStyles = showAnimatedNav ? 'navVisible' : 'navHidden';

	return (
		<div data-testid="accolades-header-container">
			<div className={`header ${headerStyles}`}>
				<div name="headerElements">
					<GlobalHeader
						isProd={isProd}
						className
						currentUrl={currentUrl}
						hideSubscribeButton={!forceButtonDisplay}
						loginButtonTrackingProps={loginButtonTrackingProps}
						menuButtonTrackingProps={menuButtonTrackingProps}
						shiftBodyOnMenuClick
						EditToolsGearIcon={<div className="center" />}
						showNewslettersSubscribeUI
						newsLetterSubscribeTrackingProps={newsLetterSubscribeTrackingProps}
						subscribeBtnProps={subscribeBtnProps}
						onSearchClick={searchClickHandler}
						data-testid="global-header-component"
					/>
				</div>
			</div>
			<div className={`accoladesNavbarContainer ${engagementStyles}`}>
				<div className="navbarContentWrapper">
					<a dangerouslySetInnerHTML={{ __html: `${forbesF}` }} href="https://www.forbes.com" aria-label="home" />
					<div className="navbarContentWrapper__divider" />
					<span className="navbarContentWrapper__text">
						<span className="navbarContentWrapper__fullText">Get in Touch</span>
						<button type="button" className="navbarContentWrapper__mobileText listuser-get-in-touch--button" onClick={handleGetInTouchTracking}>Get In Touch</button>
						{` With ${profileName}`}
					</span>
					<ButtonCTA className="navbarContentWrapper__button listuser-get-in-touch--button" variant="secondary" onClick={handleGetInTouchTracking} aria-label="Get In Touch">Get In Touch</ButtonCTA>
				</div>
			</div>
		</div>
	);
};

AccoladesHeader.propTypes = {
	isMobile: PropTypes.bool,
	headerParams: PropTypes.object,
	profileName: PropTypes.string,
};

module.exports = { AccoladesHeader };
