const { AccoladesHeader } = require('../../react-components/AccoladesHeader/AccoladesHeader');
const { useReactComponent } = require('../../../../app/shared/componentLibraryService');

const getAccoladesHeaderComponent = ({
	meta,
	isUs,
	globalHeaderData,
	isProd,
	isMobile,
	isFda,
	isNewsletter,
	profileName = '',
	showGetInTouch,
} = {}) => useReactComponent(AccoladesHeader, {
	headerParams: {
		meta,
		isUs,
		globalHeaderData,
		isProd,
		isFda,
		isNewsletter,
	},
	isMobile,
	profileName,
	showGetInTouch,
});

module.exports = {
	getAccoladesHeaderComponent,
};
